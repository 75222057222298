import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleLoginSubmit } from '../Dashboard/ApiHandler/authFunctions';

const Login = () => {
    const navigate = useNavigate();
    // User login Details
    const [userLoginData, setUserLoginData] = useState({
        email: "",
        password: "",
    })

    const handleLoginChange = (e) => {
        const { value, name } = e.target
        setUserLoginData((prev) => {
            return {
                ...prev, [name]: value
            }
        })
    }

    return (
        <div className="login-container">
            <ToastContainer />
            <div className="login-box">
                <div className="login-left">
                    <h1 className="login-title">Sign In</h1>
                    <form onSubmit={(e) => handleLoginSubmit(e, userLoginData, setUserLoginData, navigate)}>
                        <input
                            name="email"
                            type="email"
                            value={userLoginData.email}
                            placeholder="Enter Email"
                            onChange={handleLoginChange}
                            autoComplete="off"
                            required />
                        <input
                            name="password"
                            type="password"
                            value={userLoginData.password}
                            placeholder="Enter Password"
                            onChange={handleLoginChange}
                            autoComplete="off"
                            required />
                        <a href="/forgot-password" className="forgot-password">Forgot your password?</a>
                        <button type="submit" className="login-button">SIGN IN</button>
                    </form>
                </div>
                <div className="login-right">
                    <h2 className="welcome-title">Welcome to Slate - Your All-in-One Management Solution!</h2>
                    <p>Slate is designed to simplify school and document management, making workflows seamless and efficient. From organizing and managing student records to tracking attendance and streamlining administrative tasks, Slate ensures everything runs smoothly. Whether you're handling documents or overseeing school operations, we've got you covered. Let's make management smarter and more effective!</p>
                </div>
            </div>
        </div>
    );
};

export default Login;