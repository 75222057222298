import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Oval } from 'react-loader-spinner'
import { fetchActiveSchools } from '../ApiHandler/schoolFunctions';
import { fetchLabsForSchool } from '../ApiHandler/labFunctions';
import { handleSessionSetup } from '../ApiHandler/sessionFunctions';

const EXCEL_FILE_FORMAT = process.env.REACT_APP_EXCEL_FILE_FORMAT;

const SessionSetup = () => {
    const [sessionData, setSessionData] = useState({
        sessionTitle: "",
        sessionHost: "",
        sessionDate: "",
        sessionTime: "",
        schoolId: "",
        labId: "",
        sessionDescription: "",
    });
    const [attendeesFile, setAttendeesFile] = useState(null);
    const [schoolNames, setSchoolNames] = useState([]);
    const [labs, setLabs] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSessionData(prevData => ({
            ...prevData,
            [name]: value
        }));
        if (name === "schoolId") {
            fetchLabsForSchool(value, setLabs);
        }
    };

    useEffect(() => {
        fetchActiveSchools(setSchoolNames);
    }, []);

    return (
        <div className="upload-document-container">
            <ToastContainer />
            <header className="upload-document-header">
                <h1>Session Setup</h1>
                {loading ? <Oval height="22" width="22" color="blue" ariaLabel="loading" /> : ''}
            </header>
            <form className="upload-document-form" onSubmit={(e) => handleSessionSetup(e, sessionData, attendeesFile, setSessionData, setAttendeesFile, setLoading)}>
                <div className="form-group">
                    <label>Session Title <m style={{ color: 'red' }}>*</m></label>
                    <input
                        type="text"
                        name="sessionTitle"
                        value={sessionData.sessionTitle}
                        onChange={handleChange}
                        placeholder="Enter Session Title"
                        autoComplete='off'
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Session Host <m style={{ color: 'red' }}>*</m></label>
                    <input
                        type="text"
                        name="sessionHost"
                        value={sessionData.sessionHost}
                        onChange={handleChange}
                        placeholder="Enter session host name"
                        autoComplete='off'
                        required
                    />
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>Session Date <m style={{ color: 'red' }}>*</m></label>
                        <input
                            type="date"
                            name="sessionDate"
                            value={sessionData.sessionDate}
                            onChange={handleChange}
                            placeholder="Enter Session Date"
                            autoComplete='off'
                            required
                        />
                    </div>
                    <div className="form-group geo-location-container">
                        <label>Session Time <m style={{ color: 'red' }}>*</m></label>
                        <input
                            type="text"
                            name="sessionTime"
                            value={sessionData.sessionTime}
                            onChange={handleChange}
                            placeholder="Enter Session Time"
                            autoComplete='off'
                            required
                        />
                    </div>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>School Name <m style={{ color: 'red' }}>*</m></label>
                        <select name="schoolId" value={sessionData.schoolId} onChange={handleChange} required>
                            <option value="">Select</option>
                            {schoolNames.map((school) => (
                                <option key={school.id} value={school.id}>
                                    {school.school_name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Lab Name <m style={{ color: 'red' }}>*</m></label>
                        <select name="labId" value={sessionData.labId} onChange={handleChange} required>
                            <option value="">Select</option>
                            {labs.map((lab) => (
                                <option key={lab.id} value={lab.id}>
                                    {lab.lab_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="form-group">
                    <label>Session Description <m style={{ color: 'red' }}>*</m></label>
                    <textarea
                        type="text"
                        name="sessionDescription"
                        value={sessionData.sessionDescription}
                        onChange={handleChange}
                        maxLength="300"
                        placeholder="Enter Session Description"
                        autoComplete='off'
                        required
                    />
                    <label style={{ color: 'red', fontWeight: '350', fontSize: '12px' }}>Maximum Characters: 300</label>
                </div>
                <div className='in-row-input'>
                    <div className="form-group">
                        <label>List Of Invitees <m style={{ color: 'red' }}>*</m></label>
                        <input type="file" onChange={(e) => { setAttendeesFile(e.target.files[0]) }} required />
                    </div>
                    <div>
                        <p>
                            Download the <a href={EXCEL_FILE_FORMAT} target="_blank" rel="noopener noreferrer">Excel File Format</a>.
                        </p>
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
                </div>
            </form>
            <div className="usage-instructions">
                <h2>📢 Usage Instructions</h2>
                <ul>
                    <li><i className='bx bx-paper-plane'></i> Download the Excel file format from the given link.</li>
                    <li><i className='bx bx-paper-plane'></i> You must add the students' details to it before uploading.</li>
                </ul>
            </div>
        </div>
    );
};

export default SessionSetup;
